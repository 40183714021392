import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { initAmplify, getSignedUrl } from "../utils/amplify";
import SettingsIcon from "@mui/icons-material/Settings";

initAmplify();

function FormAcquirer() {
  const regexNumber = /^[0-9]*$/;
  const regexAlphanumeric = /^[a-zA-Z0-9]*$/;

  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [amountErrorMsg, setAmountErrorMsg] = useState("");

  const [issuer, setIssuer] = useState("");
  const [useCPSP, setUseCPSP] = useState(false);
  const [reference, setReference] = useState("");
  const [referenceError, setReferenceError] = useState(false);
  const [referenceErrorMsg, setReferenceErrorMsg] = useState("");

  const [redirectUrl, setRedirectUrl] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [descriptionErrorMsg, setDescriptionErrorMsg] = useState("");

  const [returnUrl, setReturnUrl] = useState("");
  const [returnUrlError, setReturnUrlError] = useState(false);
  const [returnUrlErrorMsg, setReturnUrlErrorMsg] = useState("");

  const [transactionType, setTransactionType] = useState("ONLINE");
  const [transactionFlow, setTransactionFlow] = useState("STANDARD");
  const [mcc, setMcc] = useState("5812");
  const [amountType, setAmountType] = useState("FIXED");
  const [amountCurrency, setAmountCurrency] = useState("EUR");
  const [expirationPeriod, setExpirationPeriod] = useState(3600);
  const [creditorId, setCreditorId] = useState("100000001");
  const [creditorName, setCreditorName] = useState(
    "The Dutch CookieShop Company N.V."
  );
  const [countryCode, setCountryCode] = useState("NL");
  const [creditorIban, setCreditorIban] = useState("NL44RABO0123456789");
  const [creditorBic, setCreditorBic] = useState("INGBNL2AXXX");
  const [creditorSubId, setCreditorSubId] = useState(1234);
  const [creditorSubName, setCreditorSubName] = useState("Dutch CookieShop");
  const [creditorSubIban, setCreditorSubIban] = useState("NL44RABO0123456789");
  const [cpspSchemeId, setCpspSchemeId] = useState("s8f0kg85Sk");
  const [c2cProviderSchemeId, setC2cProviderSchemeId] = useState("8f9gss92ks0");
  const [expectedDebtorUserToken, setExpectedDebtorUserToken] =
    useState("string");
  const [expectedDebtorUserAgent, setExpectedDebtorUserAgent] =
    useState("string");
  const [debtorFirstName, setDebtorFirstName] = useState(true);
  const [debtorLastName, setDebtorLastName] = useState(true);
  const [debtorPhoneNumber, setDebtorPhoneNumber] = useState(true);
  const [debtorEmail, setDebtorEmail] = useState(true);
  const [checkoutDetailsShippingAddress, setCheckoutDetailsShippingAddress] =
    useState(true);
  const [checkoutDetailsInvoiceAddress, setCheckoutDetailsInvoiceAddress] =
    useState(true);
  const [transactionCallbackUrl, setTransactionCallbackUrl] = useState(
    "https://checkout.company.com/transaction/webhook/transaction-callback"
  );
  const [userTokenCallbackUrl, setUserTokenCallbackUrl] = useState(
    "https://checkout.company.com/token/webhook/user-token-callback"
  );

  const [socketClosed, setSocketClosed] = useState(false);

  const [valueButton, setValueButton] = useState("Show Extra Fields");
  const [extraFieldsShowed, setExtraFieldsShowed] = useState(false);
  const [extraFieldsButtonIcon, setExtraFieldsButtonIcon] = useState(
    <ArrowDropDownIcon />
  );

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const redirectToURL = () => window.location.href = redirectUrl;
  function validateAmount(value) {
    setAmount(value);
    if (!regexNumber.test(value)) {
      setAmountError(true);
      setAmountErrorMsg("Amount must be a number (in eurocents)");
    } else if (value == "") {
      setAmountError(true);
      setAmountErrorMsg("Amount is required");
    } else {
      setAmountError(false);
      setAmountErrorMsg("");
    }
  }

  function showHideExtraFields() {
    if (extraFieldsShowed) {
      setExtraFieldsShowed(false);
      setValueButton("Show Extra Fields");
      document.getElementById("advanced-fields").style.display = "none";
      setExtraFieldsButtonIcon(<ArrowDropDownIcon />);
    } else {
      setExtraFieldsShowed(true);
      setValueButton("Hide Extra Fields");
      document.getElementById("advanced-fields").style.display = "inline";
      setExtraFieldsButtonIcon(<ArrowDropUpIcon />);
    }
  }

  function validateDescription(value) {
    setDescription(value);
    if (value === "") {
      setDescriptionError(true);
      setDescriptionErrorMsg("Description is required");
    } else if (value.length > 35) {
      setDescriptionError(true);
      setDescriptionErrorMsg("Length must be 35 or lower");
    } else {
      setDescriptionError(false);
      setDescriptionErrorMsg("");
    }
  }

  function validateReturnUrl(value) {
    setReturnUrl(value);
    if (value === "") {
      setReturnUrlError(true);
      setReturnUrlErrorMsg("ReturnURL is required");
    } else {
      setReturnUrlError(false);
      setReturnUrlErrorMsg("");
    }
  }

  function validateReference(value) {
    setReference(value);
    if (value == "" || value.length == 0) {
      setReferenceError(true);
      setReferenceErrorMsg("Reference is required");
    } else if (!regexAlphanumeric.test(value)) {
      setReferenceError(true);
      setReferenceErrorMsg("Reference must contain only letters and numbers");
    } else if (value.length > 35) {
      setReferenceError(true);
      setReferenceErrorMsg("Length must be 35 or lower");
    } else {
      setReferenceError(false);
      setReferenceErrorMsg("");
    }
  }

  function handleSubmit() {
    validateAmount(amount.trim());
    validateDescription(description.trim());
    validateReference(reference.trim());
    validateReturnUrl(returnUrl.trim());

    if (
      amountError ||
      amount === "" ||
      descriptionError ||
      description === "" ||
      referenceError ||
      reference === "" ||
      returnUrlError ||
      returnUrl === ""
    ) {
      return false;
    }

    const body = {
      amount: {
        amount,
        type: amountType,
        currency: amountCurrency,
      },
      description,
      reference,
      expirationPeriod,
      transactionType,
      transactionFlow,
      creditor: {
        id: creditorId,
        name: creditorName,
        sub: {
          id: creditorSubId,
          name: creditorSubName,
          iban: creditorSubIban,
        },
        cpsp: useCPSP,
        cpspSchemeId,
        c2cProviderSchemeId,
        countryCode,
        iban: creditorIban,
        bic: creditorBic,
      },
      expectedDebtor: {
        userToken: expectedDebtorUserToken,
        userAgent: expectedDebtorUserAgent,
      },
      requestedCheckoutDetails: {
        debtorContactDetails: {
          firstName: debtorFirstName,
          lastName: debtorLastName,
          phoneNumber: debtorPhoneNumber,
          email: debtorEmail,
        },
        shippingAddress: checkoutDetailsShippingAddress,
        invoiceAddress: checkoutDetailsInvoiceAddress,
      },
      mcc,
      transactionCallbackUrl,
      userTokenCallbackUrl,
      returnUrl,
      issuerId: issuer
    };

    getSignedUrl().then((signedUrl) => {
      let socketRef;
      if (signedUrl.length) {
        handleShow();
        console.log(signedUrl);
        socketRef = new WebSocket(`${signedUrl}&validation=false`);
        if (socketClosed && socketRef) {
          setSocketClosed(false);
        }

        try {
          socketRef.onopen = () => {
            // Send data through the WebSocket after the connection is established and the $connect event has returned a 200 status code
            if (socketRef && socketRef.readyState === WebSocket.OPEN) {
              socketRef?.send(JSON.stringify(body));
            }
          };
        } catch (e) {
          handleClose();
          console.error(
            "An error occured initializing the transaction creation:",
            e
          );
        }

        // need to handle this still
        socketRef.onerror = (event) => {
          handleClose();
          console.error("A general error occurred with the WebSocket:", event);
        };

        // wait for message from ITT
        socketRef.onmessage = (event) => {
          try {
            const message = JSON.parse(event.data);
            console.log(message)
            if(message?.links?.redirectUrl?.href 
              && message?.links?.redirectUrl?.href !== "null" 
              && message?.links?.redirectUrl?.href !== ""
              && message?.links?.redirectUrl?.href !== null){
              setRedirectUrl(message?.links?.redirectUrl?.href)
              redirectToURL()
            }else{
              alert("Error on the request, please try again...")
            }
            socketRef.close();
          } catch (e) {
            handleClose();
            console.error("An error occurred while parsing the message:", e);
          }
        };

        socketRef.onclose = (event) => {
          console.log("Closing...");
          handleClose();
          setSocketClosed(true);
        };
      }
    });
  }

  return (
    <Container fluid style={{ width: 70 + "%" }}>
      <div>
        <span label="Create Transaction">
          <Typography variant="h3" gutterBottom>
            Create Transaction
          </Typography>
        </span>
      </div>
      <div className="div-margin">
        <Row>
          <Col>
            <TextField
              size="small"
              id="amount"
              required
              error={amountError}
              helperText={amountError ? amountErrorMsg : "In eurocents"}
              label="Amount"
              type="text"
              fullWidth
              value={amount}
              onChange={(e) => validateAmount(e.target.value)}
            />
          </Col>
          <Col>
            <TextField
              size="small"
              id="issuer"
              label="Issuer"
              type="text"
              value={issuer}
              fullWidth
              onChange={(e) => setIssuer(e.target.value)}
            ></TextField>
          </Col>
        </Row>
      </div>
      <div className="div-margin">
        <Row>
          <Col>
            <TextField
              size="small"
              required
              id="reference"
              label="Reference"
              type="text"
              value={reference}
              error={referenceError}
              helperText={referenceErrorMsg}
              fullWidth
              onChange={(e) => validateReference(e.target.value)}
            ></TextField>
          </Col>
          <Col>
            <TextField
              size="small"
              required
              id="description"
              label="Description"
              type="text"
              value={description}
              error={descriptionError}
              helperText={descriptionErrorMsg}
              fullWidth
              onChange={(e) => validateDescription(e.target.value)}
            ></TextField>
          </Col>
        </Row>
      </div>
      <div className="div-margin">
        <Row>
          <Col md="8">
            <TextField
              size="small"
              required
              id="returnUrl"
              label="Return URL"
              type="text"
              value={returnUrl}
              error={returnUrlError}
              helperText={returnUrlErrorMsg}
              placeholder="https://dummy.com"
              fullWidth
              onChange={(e) => validateReturnUrl(e.target.value)}
            ></TextField>
          </Col>
          <Col md="4">
            <FormControlLabel
              control={<Checkbox id="useCPSP" checked={useCPSP}
              onChange={(e) => setUseCPSP(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}/>}
              label="Use a CPSP submerchant"
            />
          </Col>
        </Row>
      </div>
      <div id="advanced-fields" style={{ display: "none" }}>
        <div className="div-margin">
          <Row>
            <Col>
              <TextField
                size="small"
                id="transactionType"
                label="Transaction Type"
                type="text"
                value={transactionType}
                fullWidth
                onChange={(e) => setTransactionType(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="transactionFlow"
                label="Transaction Flow"
                type="text"
                value={transactionFlow}
                fullWidth
                onChange={(e) => setTransactionFlow(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="mcc"
                label="MCC"
                type="text"
                value={mcc}
                fullWidth
                onChange={(e) => setMcc(e.target.value)}
              />
            </Col>
          </Row>
        </div>
        <div className="div-margin">
          <Row>
            <Col>
              <TextField
                size="small"
                id="amountType"
                label="Amount Type"
                type="text"
                value={amountType}
                fullWidth
                onChange={(e) => setAmountType(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="amountCurrency"
                label="Amount Currency"
                type="text"
                value={amountCurrency}
                fullWidth
                onChange={(e) => setAmountCurrency(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="expirationPeriod"
                label="Expiration Period"
                type="text"
                value={expirationPeriod}
                fullWidth
                onChange={(e) => setExpirationPeriod(e.target.value)}
              />
            </Col>
          </Row>
        </div>
        <div className="div-margin">
          <Row>
            <Col>
              <TextField
                size="small"
                id="creditorId"
                label="Creditor Id"
                type="text"
                value={creditorId}
                fullWidth
                onChange={(e) => setCreditorId(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="creditorName"
                label="Creditor Name"
                type="text"
                value={creditorName}
                fullWidth
                onChange={(e) => setCreditorName(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="countryCode"
                label="Country Code"
                type="text"
                value={countryCode}
                fullWidth
                onChange={(e) => setCountryCode(e.target.value)}
              />
            </Col>
          </Row>
        </div>

        <div className="div-margin">
          <Row>
            <Col>
              <TextField
                size="small"
                id="creditorIban"
                label="Creditor IBAN"
                type="text"
                value={creditorIban}
                fullWidth
                onChange={(e) => setCreditorIban(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="creditorBic"
                label="Creditor BIC"
                type="text"
                value={creditorBic}
                fullWidth
                onChange={(e) => setCreditorBic(e.target.value)}
              />
            </Col>
          </Row>
        </div>

        <div className="div-margin">
          <Row>
            <Col>
              <TextField
                size="small"
                id="creditorSubId"
                label="Creditor Sub Id"
                type="text"
                value={creditorSubId}
                fullWidth
                onChange={(e) => setCreditorSubId(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="creditorSubName"
                label="Creditor Sub Name"
                type="text"
                value={creditorSubName}
                fullWidth
                onChange={(e) => setCreditorSubName(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="creditorSubIban"
                label="Creditor Sub IBAN"
                type="text"
                value={creditorSubIban}
                fullWidth
                onChange={(e) => setCreditorSubIban(e.target.value)}
              />
            </Col>
          </Row>
        </div>
        <div className="div-margin">
          <Row>
            <Col>
              <TextField
                size="small"
                id="cpspSchemeId"
                label="CPSP Scheme Id"
                type="text"
                value={cpspSchemeId}
                fullWidth
                onChange={(e) => setCpspSchemeId(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="c2cProviderSchemeId"
                label="C2C Provider Scheme Id"
                type="text"
                value={c2cProviderSchemeId}
                fullWidth
                onChange={(e) => setC2cProviderSchemeId(e.target.value)}
              />
            </Col>
          </Row>
        </div>
        <div className="div-margin">
          <Row>
            <Col>
              <TextField
                size="small"
                id="expectedDebtorUserToken"
                label="Expected Deptor User Token"
                type="text"
                value={expectedDebtorUserToken}
                fullWidth
                onChange={(e) => setExpectedDebtorUserToken(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="expectedDebtorUserAgent"
                label="Expected Debtor User Agent"
                type="text"
                value={expectedDebtorUserAgent}
                fullWidth
                onChange={(e) => setExpectedDebtorUserAgent(e.target.value)}
              />
            </Col>
          </Row>
        </div>
        <div className="div-margin">
          <Row>
            <Col>
              <TextField
                size="small"
                id="transactionCallbackUrl"
                label="Transaction Callback Url"
                type="text"
                value={transactionCallbackUrl}
                fullWidth
                onChange={(e) => setTransactionCallbackUrl(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="userTokenCallbackUrl"
                label="User Token Callback Url"
                type="text"
                value={userTokenCallbackUrl}
                fullWidth
                onChange={(e) => setUserTokenCallbackUrl(e.target.value)}
              />
            </Col>
          </Row>
        </div>
        <div className="div-margin">
          <Row>
            <Col>
              <FormControlLabel
                control={
                  <Checkbox
                    id="debtorFirstName"
                    checked={debtorFirstName}
                    onChange={(e) => setDebtorFirstName(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Request Debtor First Name"
              />
            </Col>
            <Col>
            <FormControlLabel
                control={
                  <Checkbox
                    id="debtorLastName"
                    checked={debtorLastName}
                    onChange={(e) => setDebtorLastName(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Request Debtor Last Name"
              />
            </Col>
            <Col>
            <FormControlLabel
                control={
                  <Checkbox
                    id="debtorPhoneNumber"
                    checked={debtorPhoneNumber}
                    onChange={(e) => setDebtorPhoneNumber(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Request Debtor Phone Number"
              />
            </Col>
          </Row>
        </div>
        <div className="div-margin">
          <Row>
            <Col>
              <FormControlLabel
                control={
                  <Checkbox
                    id="debtorEmail"
                    checked={debtorEmail}
                    onChange={(e) => setDebtorEmail(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Request Debtor Email"
              />
            </Col>
            <Col>
            <FormControlLabel
                control={
                  <Checkbox
                    id="checkoutDetailsShippingAddress"
                    checked={checkoutDetailsShippingAddress}
                    onChange={(e) => setCheckoutDetailsShippingAddress(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Request Checkout Detail Shipping Address"
              />
            </Col>
            <Col>
              <FormControlLabel
                control={
                  <Checkbox
                    id="checkoutDetailsInvoiceAddress"
                    checked={checkoutDetailsInvoiceAddress}
                    onChange={(e) => setCheckoutDetailsInvoiceAddress(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Request Checkout Detail Invoice Address"
              />
            </Col>
          </Row>
        </div>
      </div>


      <Row style={{paddingBottom:"3%"}}>
        <Col md="3"></Col>
        <Col md="3">
          <Button
            variant="contained"
            onClick={showHideExtraFields}
            endIcon={extraFieldsButtonIcon}
            fullWidth
            color="inherit"
          >
            {valueButton}
          </Button>
        </Col>
        <Col md="3">
          <Button
            variant="contained"
            onClick={handleSubmit}
            endIcon={<SendIcon />}
            color="primary"
          >
            Submit
          </Button>
        </Col>
        <Col md="3"></Col>
      </Row>

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body style={{ textAlign: "center" }}>
          <Typography variant="h4">Creating transaction... </Typography>
          <br />
          <SettingsIcon className="image" fontSize="large" />
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default FormAcquirer;
