
import * as React from 'react';
import './App.css';
import FormAcquirer from './components/form';
import 'bootstrap/dist/css/bootstrap.min.css';
import ResponsiveAppBar from './components/header';

function App() {
  return (
    <div className="App">
        <ResponsiveAppBar />
        <FormAcquirer></FormAcquirer>
    </div>
  );
}



export default App;