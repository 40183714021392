import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

function ResponsiveAppBar() {
  return (
    <AppBar position="static" sx={{backgroundColor:'#ebf1f4', height: 20+"%", marginBottom: 2+"%"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src='./iDEAL.png' style={{width:5+"%", height:5+"%"}}></img>
            <span style={{color: "black"}}> Het <b>Nieuwe</b> iDEAL</span>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;








