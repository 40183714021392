import { Signer } from "@aws-amplify/core";
import { Amplify, Auth } from "aws-amplify";

export function initAmplify(){
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_POOL_ID,
      region: process.env.REACT_APP_REGION,
    }
  });
}

export const getSignedUrl = async () => {
  const credentials = await Auth.currentCredentials();
  if (!credentials.accessKeyId) { throw Error('User is not authorized') }
  const accessInfo = {
    access_key: credentials.accessKeyId,
    secret_key: credentials.secretAccessKey,
    session_token: credentials.sessionToken,
  };
  return Signer.signUrl(process.env.REACT_APP_WEBSOCKET, accessInfo);
};
